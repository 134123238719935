.input-header {
  color: #333333;
  font-weight: bold;
  margin: 1.25em auto 0;
  font-size: 0.875rem;
  line-height: 1.25;
  display:flex;

  &.inputLabel {
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
}

.show-pw {
  right: 25px;
  text-decoration: underline;
  text-transform: uppercase;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.address-header {
  margin-top: 1.5rem;
  margin-bottom: 0.4rem !important;
}

.address-lookup {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 1rem;
  position: relative;
  top: -8px;

  @media screen and (min-width: 768px) {
    top: auto;
  }
}

.field-flex {
  display: flex;
  align-items: baseline;

  label {
    position: relative;
  }

  div {
    &:first-of-type {
      margin-bottom: 1em;
      margin-right: 15px;
      margin-bottom: 0;

      @media screen and (min-width: 520px) {
        flex-grow: 2;
      }
    }

    &:last-of-type {
      flex-shrink: 1;

    @media screen and (min-width: 768px) {
      flex-basis: 40%;
    }
    }
  }
}

.form-error {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: rgba(252, 129, 129, 1);
}
#sms-input {
  margin-right: 10px;
}

label[for="sms"] {
  font-size: 14px !important;
}

.scene-card-field {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  label {
    font-size: 14px !important;
    margin: 0.2rem auto 0;
  }

  p {
    font-size: 0.8rem;
  }
}
#spinner-widget {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.75;
  z-index: 1000;
  display: none;
}

@media screen and (min-width: 520px) {
  .cp-search-wrapper {
    position: relative;
    line-height: 1.25;

    &:before {
      content: "";
      position: absolute;
      top: 1.5rem;
      right: 0;
      width: 3rem;
      height: 53px;
      background: url("/assets/magnifying-icon.svg");
      background-repeat: no-repeat;
      background-position: right 15px center;
      margin-bottom: 0;
    }
  }
}
